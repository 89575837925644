function scale (number, inMin, inMax, outMin, outMax) {
  return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}
var surface = window.innerWidth * window.innerHeight;

var maps = {
  initialization:true,
  map:null,
  map_el:null,
  
  zoom: 10,
  // zoom: scale(surface, 200000, 3000000, 7, 9.8 ),
  lng: null,
  lat: null,
  mode: null,
  center: null,
  start_center: null,

  bounds : [41.530427, -1.213209, 43.358137, -0.111432],

  firstSymbolId: null,

  keep: ["place-city-label-minor", "place-city-label-major","place-town-village-hamlet-label" ],

  markers: [],

  route : {
    'type': 'geojson',
    'data': {
      'type': 'Feature',
      'properties': {},
      'geometry': {
        'type': 'LineString',
        'coordinates': []
      }
    }
  },

  //---------------------------------------------------- init
  init: function(){

    mapboxgl.accessToken = 'pk.eyJ1IjoiZmFpbGxlc2Zyb250ZXJhcyIsImEiOiJja3F1b2JsamowNmhzMnhwcHgzNmJmNGx4In0.L2WAv7_JMPRVeaL8IyEF1w';
    maps.map_el = document.querySelector('.map');

    if(maps.initialization==false) return;
        
    maps.lng = parseFloat(maps.map_el.getAttribute('data-lng'));
    maps.lat = parseFloat(maps.map_el.getAttribute('data-lat'));
    maps.center = [maps.lng, maps.lat];
    maps.average_center = [(maps.bounds[1] + maps.bounds[3]) / 2, (maps.bounds[0] + maps.bounds[2]) / 2];

    var start_lng = maps.lng;
    var start_lat = maps.lat;    
    
    maps.start_center = [
      start_lng, start_lat
    ];

    console.log(maps.start_center)
    
    maps.map = new mapboxgl.Map({
      container: maps.map_el.id, // container id
      style: 'mapbox://styles/faillesfronteras/ckquobvyl14bx17mctony5y5y', // stylesheet location
      center: maps.start_center, // starting position [lng, lat]
      zoom: 11, // starting zoom
      maxZoom: 16,
      minZoom: 7,
      pitch: 60, // pitch in degrees
      // bearing: -60, // bearing in degrees
    });

    // controls
    maps.map.scrollZoom.disable();
    maps.map.addControl(new mapboxgl.NavigationControl());
    // maps.map.addControl(new mapboxgl.FullscreenControl());
    // maps.map.addControl(
    //   new mapboxgl.GeolocateControl({
    //       positionOptions: { enableHighAccuracy: true },
    //       trackUserLocation: true
    //   })
    // );

    maps.initialization = false;
    maps.styleMap();
  },
  
  //---------------------------------------------------- styleMap
  styleMap: function(){
    maps.map.on('load', function () {
      // don’t display labels on general map
      maps.map.style.stylesheet.layers.forEach(function(layer) {
        if (layer.type === 'symbol') {
          maps.map.setLayoutProperty(layer.id, 'visibility', 'none');
        }
      });

      var layers = maps.map.getStyle().layers;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol') {
          maps.firstSymbolId = layers[i].id;
          break;
        }
      }
      
      // contour / levels lines
      maps.map.addSource('mapbox-terrain', { type: 'vector', url: 'mapbox://mapbox.mapbox-terrain-v2' });      
      maps.map.addLayer({ 'id': 'terrain-data', 'type': 'line', 'source': 'mapbox-terrain', 'source-layer': 'contour', 'layout': {   'line-join': 'round',   'line-cap': 'round' }, 'paint': {   'line-color': '#000',   'line-width': 1 } }, maps.firstSymbolId);

      // bounds
      var bounds = new mapboxgl.LngLatBounds() ;
      maps.map_el.classList.add("styled");
      
      // resize
      window.addEventListener('resize', maps.resizeCallback);
      maps.map.resize();
      // maps.map.fitBounds([ [maps.bounds[0], maps.bounds[1]], [maps.bounds[2], maps.bounds[3]] ]);
      
 
      maps.gotoMap();
    });
  },

  gotoMap: function(){
    maps.addMarkers();
    maps.drawRoute();
    maps.map.fitBounds([ [maps.bounds[3], maps.bounds[2]], [maps.bounds[1], maps.bounds[0]] ], {padding: 20});
    console.log([maps.bounds[0], maps.bounds[1]], [maps.bounds[2], maps.bounds[3]] )
    // maps.map.flyTo({
    //   center: maps.average_center,
    //   zoom: maps.zoom
    // })
    
  },

  drawRoute: function(){
    maps.map.addSource('route', maps.route);
    maps.map.addLayer({
      'id': 'route',
      'type': 'line',
      'source': 'route',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': 'rgba(255,255,255,0.4)',
        'line-width': 2
      }
    });
  },

  //---------------------------------------------------- addMarkers
  addMarkers:function(){
    // 
    maps.map.style.stylesheet.layers.forEach(function(layer) {
      if (layer.type === 'symbol' ) {
        maps.map.setLayoutProperty(layer.id,'visibility', 'visible');
      }
    });     
    
    document.markers.forEach( function(marker) {
      // create a HTML element for each feature
      var el = document.createElement('a');
      var span = document.createElement('span');
      span.innerHTML = "<em>" + marker.title + "</em>";
      el.className = 'marker';
      // marker.categories.forEach(function(cat){
      //   el.classList.add(cat);
      // });
      // el.classList.add("picto-" + marker.picto);
      el.setAttribute('id', 'marker-' + marker.id);
      var rot = Math.round(Math.random() * 360);
      // span.style.transform = "rotate(" + rot + "deg)";
      el.setAttribute('href', marker.url);    
      el.appendChild(span);   

      // make a marker for each feature and add to the map
      var m = new mapboxgl.Marker(el)
          .setLngLat(marker.latlon)
          .addTo(maps.map);
      
      maps.markers.push(el);
      // add coords to route
      maps.route.data.geometry.coordinates.push(marker.latlon);

      el.addEventListener('click', function(e){
        e.preventDefault();
        loadDetail(this.getAttribute('href'));
      });
    });

  },
  
  //---------------------------------------------------- resize
  resizeCallback : function(){ 
    throttle(maps.resizeMap, 100);
  },
  resizeMap:function(){
    if (maps.map !== null) maps.map.resize();  
  },
  

};




//------------------------------------------------------ POI show popup 

function buildDetail(content){
  var main = document.querySelector('#main');
  main.classList.remove('visible');
  main.innerHTML = "";

  if(content == false){
    main.classList.remove('visible');
    document.body.classList.remove('ajax-article');
  } else {

    // close button
    var close = document.createElement('button');
    close.className = "close";
    close.innerHTML = "<span>×</span>";
    main.appendChild(close);

    close.addEventListener('click', function(){
      main.classList.remove('visible');  
      history.pushState(null, null, document.site_url);
      document.body.classList.remove('ajax-article');
      setTimeout(function(){
        main.removeChild(content);
      }, 250);
    });

    // done loading
    document.querySelector('.map').scrollIntoView({ 
      behavior: 'smooth',
      block: "end",
    });
    main.classList.add('visible');
    main.appendChild(content);
  }
}

// ------------------------------------------------ POI data load

function loadDetail(href){
  console.log(href);
  history.pushState(null, null, href);
  document.body.classList.add('ajax-article');

  fetch(href)
  .then(function(response) {
    return response.text();
  })
  .then(function(html) {
    // Initialize the DOM parser
    var parser = new DOMParser();

    // Parse the text
    var doc = parser.parseFromString(html, "text/html");
    var content = doc.querySelector('#content');
    var template = doc.querySelector('body').dataset.intendedtemplate;
    if(template == 'article'){
      buildDetail(content);
    } else if(template == 'about'){ 
      buildDetail(content);
    } else {
      buildDetail(false);
    }
  })

  .catch(function(error) {
    console.log(error);
  });
}


var main = document.querySelector('#main');
if(main){
  main.addEventListener('click', function(e){
    if(e.target.matches('a.prev') || e.target.matches('a.next') ){
      e.preventDefault();
      loadDetail(e.target.href);
    }
  })
}


// ------------------------------------------------ INIT


maps.mode = "single";
maps.init();


window.onpopstate = function (e) {
  loadDetail(document.location);
};


// ------------------------------------------------ Helpers

// Throttle
// Credit: https://jsfiddle.net/jonathansampson/m7G64
function throttle(callback,limit){
  var wait=false;return function(){
    if(!wait){
      callback.call();
      wait=true;
      setTimeout(function(){wait=false;},limit);}};}

// Hex to HSL
// Credit: https://css-tricks.com/converting-color-spaces-in-javascript/
// function hexToHSL(H){let r=0,g=0,b=0;if(H.length==4){r="0x"+H[1]+H[1];g="0x"+H[2]+H[2];b="0x"+H[3]+H[3]}else if(H.length==7){r="0x"+H[1]+H[2];g="0x"+H[3]+H[4];b="0x"+H[5]+H[6]}r/=255;g/=255;b/=255;let cmin=Math.min(r,g,b),cmax=Math.max(r,g,b),delta=cmax-cmin,h=0,s=0,l=0;if(delta==0){h=0}else if(cmax==r){h=((g-b)/delta)%6}else if(cmax==g){h=(b-r)/delta+2}else{h=(r-g)/delta+4}h=Math.round(h*60);if(h<0){h+=360}l=(cmax+cmin)/2;s=delta==0?0:delta/(1-Math.abs(2*l-1));s= +(s*100).toFixed(1);l= +(l*100).toFixed(1);return "hsl("+h+","+s+"%,"+l+"%)"}

function hexToHSL(H) {
  // Convert hex to RGB first
  var r = 0, g = 0, b = 0;
  if (H.length == 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length == 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  var cmin = Math.min(r,g,b),
      cmax = Math.max(r,g,b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

  if (delta == 0)
    h = 0;
  else if (cmax == r)
    h = ((g - b) / delta) % 6;
  else if (cmax == g)
    h = (b - r) / delta + 2;
  else
    h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0)
    h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return [h, s, l];
  // return "hsl(" + h + "," + s + "%," + l + "%)";
}



// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
function LightenDarkenColor(col,amt) {
  var usePound = false;
  if ( col[0] == "#" ) {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if ( r > 255 ) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if ( b > 255 ) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if ( g > 255 ) g = 255;
  else if  ( g < 0 ) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}