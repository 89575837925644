
function iniSlider() {
  var slider = document.querySelector(".slider");
  if(slider){
      slider.style.cursor = 'grab';

      var pos = { top: 0, left: 0, x: 0, y: 0 };

      var mouseDownHandler = function(e) {
          slider.style.cursor = 'grabbing';
          slider.style.userSelect = 'none';

          pos = {
              left: slider.scrollLeft,
              top: slider.scrollTop,
              // Get the current mouse position
              x: e.clientX,
              y: e.clientY,
          };

          document.addEventListener('mousemove', mouseMoveHandler);
          document.addEventListener('mouseup', mouseUpHandler);
      };

      var mouseMoveHandler = function(e) {
          // How far the mouse has been moved
          var dx = e.clientX - pos.x;
          var dy = e.clientY - pos.y;

          // Scroll the element
          slider.scrollTop = pos.top - dy;
          slider.scrollLeft = pos.left - dx;
      };

      var mouseUpHandler = function() {
          slider.style.cursor = 'grab';
          slider.style.removeProperty('user-select');

          document.removeEventListener('mousemove', mouseMoveHandler);
          document.removeEventListener('mouseup', mouseUpHandler);
      };

      // Attach the handler
      slider.addEventListener('mousedown', mouseDownHandler);
  }
}


document.addEventListener('DOMContentLoaded', function() {
  // iniSlider() 
});